import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/actions/regrade/[sessionId]": [~20,[4]],
		"/actions/sessionNotFound": [21,[4]],
		"/actions/updateGrade/[updateGradeId]": [22,[4]],
		"/actions/viewInbox/[campaignId]": [~23],
		"/auth": [24],
		"/auth/oauth/checkpoint": [25],
		"/auth/recover": [26],
		"/auth/register": [27],
		"/secure/client-auth-success": [28],
		"/signout": [29],
		"/[organizationId]": [~6,[2]],
		"/[organizationId]/campaigns": [7,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]": [8,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/settings": [9,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources": [~10,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources/new": [15,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]": [11,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations": [~12,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations/[integrationID]": [13,[2,3]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations/[integrationID]/edit-message/[messageID]": [14,[2,3]],
		"/[organizationId]/contacts": [16,[2]],
		"/[organizationId]/contacts/[contactUID]": [17,[2]],
		"/[organizationId]/inbox": [18,[2]],
		"/[organizationId]/team": [19,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';